import React from "react";
import "./scss/index.scss";
import logo from "src/assets/images/logoo.png";

const App = () => {
  const [isMenu, setIsMenu] = React.useState(true);
  const [transition, setTransition] = React.useState(true);
  console.log(setTransition);

  React.useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [isMenu]);

  const setTransitionTime = () =>
    setTimeout(() => {
      return setIsMenu(!isMenu);
    }, 500);

  return (
    <div className="App">
      <div className="img-2">
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              margin: "1rem 0 0 1rem",
              textAlign: "center",
              fontWeight: "700",
              fontSize: "2rem",
            }}
          >
            Goodbye Paris
          </h2>
          <a
            className="btn-link"
            href="mailto: contact@goodbyeparis.com"
            target="_blank"
            rel="noreferrer"
          >
            Nous contacter
          </a>
        </div>
      </div>
      <div
        className={isMenu ? "img-1 appear" : "img-1"}
        style={{ position: "relative" }}
        hidden={!transition}
      >
        <div className="container">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              width="50%"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setTransitionTime();
                setIsMenu(!isMenu);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="appear"
        style={{
          position: "absolute",
          width: "100%",
          top: "150%",
          left: "50%",
          zIndex: 100,
          backgroundColor: "black",
          height: "100vh",
          transform: "translate(-50%,-50%)",
        }}
        hidden={isMenu}
      >
        <div className="container">
          <div
            className="logo"
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100vh",
                width: "100%",
              }}
              onClick={() => {
                setTransitionTime();
                setIsMenu(!isMenu);
              }}
            >
              <a
                href="https://www.instagram.com/goodbyeparismusic/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/goodbyeparismusic/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com/GoodbyeParisOffparismusic/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://open.spotify.com/artist/5m396dKBV8EttaAG5FAqX7"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Spotify
              </a>
            </div>
            {/* <img
              src={terra}
              alt="img terre"
              width="30%"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setTransitionTime();
                setIsMenu(!isMenu);
              }}
            /> */}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >

            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
